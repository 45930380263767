import React from 'react';
import { mount, compose } from 'navi';
import TopApp from './top';
import NewsApp from './news';
import ProfileApp from './profiles';

const apps = compose(

  mount({
    '/': TopApp,
    '/news': NewsApp,
    '/profiles': ProfileApp
  }),
);

export default apps;
