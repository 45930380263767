import React from 'react';
import { LazyImageFull, ImageState } from 'react-lazy-images';
import classNames from 'classnames';

interface PhotoProps {
  background?: boolean;
  src: string;
  aspect?: "landscape" | "portrait";
}

type Props = React.ImgHTMLAttributes<HTMLImageElement> & PhotoProps;

export function Photo({ aspect, children, className, src, alt, background }: Props) {

  const style = {
    backgroundImage: background ? `url(${src})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  };
  return (
    <figure className={classNames('image as-photo', {
      "is-landscape": aspect === "landscape",
      "is-portrait": aspect === "portrait",
      "is-1by1": !aspect,
    },className)} style={background ? style : undefined}>
      <picture>
        <LazyImageFull src={src} alt={alt}>
          {({ imageProps, imageState, ref }) => {
            return (
              <>
                <div className={classNames({
                  "is-hidden": imageState === ImageState.LoadSuccess
                })}>
                  <div className="dot-flashing" />
                </div>
                <img
                  {...imageProps}
                  className={classNames({
                    'is-load-success': imageState === ImageState.LoadSuccess,
                  })}
                  ref={ref}
                />
              </>
            );
          }}
        </LazyImageFull>
        {children}
      </picture>
    </figure>
  );
}
